a, a:hover, a:focus {
    color: #fff;
    text-decoration: none;
}

body {
    font-family: 'Noto Sans TC', sans-serif;
    background-color: #000;
    color: #fff;
    position: relative;
}

.color-orange { color: #f36f21!important; }
.color-gold { color: #b3903b!important; }

.btn { border-radius: 0; padding: .4rem 2.5rem; }
.btn.focus, .btn:focus {
    box-shadow: none; 
}
.btn-primary, .btn-primary:hover { color: #000; background-color: #f36f21; border-color: #f36f21; }
.btn-outline-primary {  border-color: #fff; color: #fff; }
.btn-outline-primary.focus, .btn-outline-primary:focus { -webkit-box-shadow: none; box-shadow: none; }
.btn-outline-primary:hover { background-color: #fff; border-color: #fff; color: #000; }


.font-w300 {
    font-weight: 300;
}

/* header & Nav */
header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 900;
}

header .logo {
    height: 48px;
    margin-left: 16px;
    margin-top: 15px;
}

header .lang {
    font-size: 13px;
    color: #f36f21;
}
header .lang span {
    display: inline-block;
    margin: 0 .5em;
}

header>div {
    text-align: right;
    padding-right: 20px;
}

header nav.main-nav {
    background-color: #000000;
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    border-top: 6px solid #f36f21;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
header nav.main-nav .logo-box>img {
    display: block;
}
header nav.main-nav .logo-box .logo {
    width: 114px;
    margin-bottom: 4px;
}
header nav.main-nav .logo-box .logo2 {
    width: 90px;
    margin-left: 4px;
}
header nav.main-nav .menu-close-xs {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 34px;
    opacity: 1;
}
header nav.main-nav .close>img {
    width: 34px;
    height: 34px;
}
header nav.main-nav>div {
    margin: auto;
    box-sizing: border-box;
    padding: 30px 20px;
}

header nav.main-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: .5em;
}

header nav.main-nav ul .li-lang>a {
    border-top: 2px solid #f36f21;
    margin-top: 1em;
}
header nav.main-nav ul>li>a {
    padding: .25em 0;
    font-size: 22px;
    display: inline-block;
}
header nav.main-nav ul>li .submenu>a {
    opacity: .5;
    display: block;
    font-size: 18px;
    padding: .25em 0;
}

header .menu-xs {
    width: 60px;
    height: 50px;
    display: block;
    margin: 10px 15px 0 0;
    background-color: rgba(0,0,0,.8);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 11px;
}
header .menu-xs>img {
    width: 100%;
    height: 100%;
}

header#navbar {
  .logo {
    height: 76px;
  }
}

footer {
    position: relative;
}
footer>div {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer .social-networks {
    margin-bottom: 2.5em;
}

footer .copyright a {
    opacity: .8;
    font-size: 14px;
}
footer .copyright span.space {
    opacity: .8;
    font-weight: 300;
    font-size: 12px;
    display: inline-block;
    transform: translateY(-.15em);
}

.gotop {
    position: fixed;
    z-index: 90;
    display: block;
    right: 10px;
    bottom: 50px;
    width: 40px;
    height: 100px;
    opacity: 0;
     -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.gotop.show {
    opacity: 1;
}
/* Section */
section {
    position: relative;
}

img.arrow {
    width: 40px; 
}
.back img.arrow {
    opacity: .8;
    width: 32px;
    transform: translateY(-3px) scaleX(-1);
}
h1.main-title {
    font-weight: 400;
    text-align: center;
    display: block;
    margin: 0;
    padding: 1rem 0;
    margin-bottom: .5em;
    width: 100%;
    font-size: 2rem;
}

section.home {
    width: 100%;
    height: 100vh!important;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

section.home .lab {
    position: absolute;
    display: block;
    right: 0;
    bottom: 80px;
}

section.home .logo-box {
    position: absolute;
    left: 12px;
    right: 12px;
    top: 12px;
}
section.home .logo-box>img {
    display: block;
}
section.home .logo-box .logo {
    width: 82px;
    margin-bottom: 6px;
}
section.home .logo-box .logo2 {
    width: 290px;
    margin: auto;
    margin-top: 50%;
}

.marquee-box {
    margin: auto;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.marquee-box>div {
    width: 80%;
    box-sizing: border-box;
}
.marquee-box>.title {
    background-color: #f36f21;
    text-align: center;
    color: #fff;
    width: 20%;
    border-radius: 18px 0 0 18px;
    height: 36px;
    line-height: 36px;
}
.marquee-box>div:last-child {
    border-radius: 0 18px 18px 0;
}
.marquee {
    overflow: hidden;
    background: #fff;
    padding: 0 1em;
    height: 36px;
    width: 200px;
}
.marquee a {
    display: table-cell;
    height: 36px;
    vertical-align: middle;
}

.marquee a {
    color: #000;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -38px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 38px;
    background-color: transparent;
    border: none;
    text-indent: -999999px;
    outline: 0;
    padding: 0;
    z-index: 90;
}
.slick-prev {
    left: 5px;
    background-image: url(../images/prev.svg)!important;
}
.slick-next {
    right: 5px;
    background-image: url(../images/next.svg)!important;
}

#menu .v-line {
    height: 4em;
    width: 1px;
    overflow: hidden;
    background-color: #f36f21;
}

#menu .menu-btn>p {
    line-height: 2em;
    letter-spacing: .2em;
}

#menu .icon {
    display: block;
    width: 24px;
    height: 24px;
}

#menu .menu-left, #menu .menu-right {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
#menu .show {
    opacity: 1;
    z-index: 20;
}

#menu .menu-left .bg {
    background: url(../images/menu-left2.jpg) #000 no-repeat;
}
#menu .menu-right .bg {
    background: url(../images/menu-right2.jpg) #000 no-repeat;
}
#menu .menu-box {
    .type-name {
        max-height: 106px;
        margin-bottom: 2rem;
    }
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        z-index: 0;
    }
} 

#menu svg {
    display: block;
    height: auto;
    width: 100%;
}

#menu .go-box {
    width: 20%;
    box-sizing: border-box;
    height: 100%;
    background-color: #FF6517;
    position: relative;
    padding: 0 25px;
    padding-bottom: 45px;
    z-index: 5;
}
#menu .go-box>* {
    position: relative;
    z-index: 5;
}
#menu .go-box:after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    top: 0;
    width: 15%;
    background-color: #FF6517;
    z-index: 0;
}

#menu .go-box.float-left {
    transform:translateX(-120%);
}
#menu .menu-right .bg {
    opacity: 0;
    transform: translateX(10%);
}

#menu .go-box.float-right {
    transform:translateX(120%);
}
#menu .menu-left .bg {
    opacity: 0;
    transform:translateX(-10%);
}

#menu .menu-box {
    overflow: hidden;
    background-color: #000;
}
#menu .menu-box.show .go-box,
#menu .menu-box.show .bg {
    opacity: 1;
    transform:translateX(0);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    -webkit-transition-delay: .4s; /* Safari */
    transition-delay: .4s;
}

#menu .go-box.float-left:after {
    transform-origin: right top;
    transform:skewX(2.3deg);
    right: 0;
}
#menu .go-box.float-right:after {
    transform-origin: left top;
    transform:skewX(-2.3deg);
    left: 0;
}
#menu .icons-box {
    width: 80%;
    box-sizing: border-box;
    height: 100%;
    z-index: 5;
    position: relative;
}
#menu .icons-box>div {
    width: 100%;
    padding: 35px 0 15px 0;
    max-width: 488px;
    border: 12px solid #FF6517;
    box-sizing: border-box;
    margin: auto;
    background-color: rgba(0,0,0,.7);
}
#menu .icons-box h1 {
    font-size: 56px;
}
#menu .icons-box h6 {
    margin-bottom: 2em;
}
#menu .icons-box>div>p>a, #menu .icons-box>div>p>span {
    display: inline-block;
    text-align: center;
    font-size: 18px;
    color: #ff853d;
    margin: 0 1em;
}
#menu .icons-box>div>p>a>img, #menu .icons-box>div>p>span>img {
    margin: auto;
    width: 52px;
    height: 52px;
}

.navpage .mobile-btns {
    overflow: hidden;
}
.navpage .mobile-btns>a {
    box-sizing: border-box;
    float: left;
    width: 50%;
}

#mMenu {
    margin-bottom: 15px;
}
#mMenu .slick-slide img {
    width: 100%;
}
#mMenu .slick-slide p {
    padding: .4em 0 .4em 0;
    border-top: 1px solid #f36f21;
    font-size: 18px;
    margin: 0;
    margin-top: .6em;
    line-height: 1.2em;
}

#news {
    background: url(../images/news-bg.jpg) no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 60px;
}
#news h1.main-title {
    padding-left: .25em;
    letter-spacing: .25em;
}

#news .row .row {
    position: relative;
}
#news .row>div {
    position: static;
}

#news .stamp {
    position: absolute;
    right: 15px;
    bottom: 65px;
    z-index: 30;
    max-width: 92px;
}
#news .new, #news .new .content {
    width: 100%;
}

#news .photo-box {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
}
#news .photo-box>.photo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 11;
    padding-right: 2.5%;
}

#news .more, #menu .more {
    margin: auto;
    margin-top: 1em;
    font-size: 16px;
    letter-spacing: .25em;
    margin-bottom: 30px;
}
#news .more>img, #menu .more>img {
    vertical-align: middle;
    transform: translateY(-3px);
}

#news .news-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

#job {
    margin-top: -41%;
    pointer-events: none;
    @media screen and (min-width: 480px) {
        margin-top: -30%;
    }
    @media screen and (min-width: 768px) {
        margin-top: -36%;
    }
    @media screen and (min-width: 998px) {
        margin-top: -26%;
    }
    @media screen and (min-width: 1200px) {
        margin-top: -18%;
    }
    @media screen and (min-width: 1400px) {
        margin-top: -13%;
    }
    @media screen and (min-width: 1920px) {
        margin-top: -8%;
    }
}


svg.join-bg {
    display: block;
    width: 100%;
    height: 547px;
    @media screen and (min-width: 1440px) {
        height: 680px;
    }
}

.join {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute; 
    padding-top: 35px;
    display: flex;
    align-items: center;
}
.join>div {
    padding-top: 30px;
    width: 100%;
}
.join>div .mobile-join-text {
    width: 100%;
}
.join>div>div {
    width: 272px;
    margin: auto;
    pointer-events: auto;
}
.join>div>div>a {
    display: block;
    border: 1px solid #fff;
    margin: auto;
    margin-top: 12px;
    color: #fff;
    padding: .4em 0;
    text-align: center;
    max-width: 250px;
}

#job-content {
    background: #f36f21;
    padding: 12px;
}
#job-content>div {
    border: 4px solid #000;
    padding: 15px;
}

#about {
    background-color: #272727;
    padding-left: 0;
    padding-right: 0;
    
    & .tab-contents {
        @media screen and (min-width: 768px) {
            min-height: 732px;   
        }
    }
}

#about ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#about ul li {
    margin-bottom: 1px;
    position: relative;
}
#about ul li a.mbtn {
    display: block;
    background-color: #d7d7d7;
    padding-right: 48px;
    position: relative;
}
#about ul li>a.mbtn>img {
    margin: auto;
    display: block;
}

#about ul li a.mbtn+div {
    display: none;
    padding: 20px 15px;
    line-height: 1.6em;
    overflow: hidden;
}

#about ul li a.mbtn:after {
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    background: url(../images/arrow-v.svg) no-repeat;
    background-size: 100% 100%;
}
#about ul li a.mbtn.selected:after {
    transform: rotate(180deg);
}

#about .mtab-content1 {
    background: url(../images/tab1m-bg.jpg) center bottom #000 no-repeat;
    background-size: 100% auto;
    padding-bottom: 70%!important;
}
#about .mtab-content2 {
    background: url(../images/tab2m-bg.jpg) no-repeat;
    background-size: cover;
    padding-bottom: 0!important;
}
#about .mtab-content3 {
    background: #14aee6 no-repeat;
    padding-bottom: 0!important;
}
#about .mtab-content3 p:last-child {
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
}

#about .tab-cover>div {
    padding: 30px 15px;
    text-align: left;
}
#about .tab-cover>div img {
    max-width: 112px;
    margin: auto;
    display: block;
}

.banner {
    border-bottom: 1px solid #f36f21;
    position: relative;
    overflow: hidden;
    &>img {
        @media screen and ( max-width: 768px ) {
            min-width: 930px !important;
        }
        @media screen and ( max-width: 512px ) {
            min-width: 600px !important;
        }
    }
}
.banner>div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
.banner h1 {
    font-weight: 400;
    font-size: 32px;
    letter-spacing: .2em;
    img {
        max-width: 280px;
        margin-bottom: .5rem;
        @media screen and ( max-width: 1100px ) {
            margin-top: 64px;
            max-width: 220px;
        }
        @media screen and ( max-width: 512px ) {
            max-width: 180px;
        }
    }
}

h2.page-title {
    font-size: 22px;
    font-weight: 400;
    opacity: .4;
    padding: 1.5em 0 1.5em .2em;
    letter-spacing: .2em;
}
h2.page-title .space {
    font-weight: 100;
    margin-top: 1em;
}
h2.page-title-w {
    opacity: 1;
}

.shops .shop {
    font-size: 14px;
    margin-bottom: 30px;
}
.shops .name-box {
    border-left: 5px solid #f36f21;
    padding-left: 6px;
    position: relative;
}
.shops .name-box>h3 {
    font-size: 18px;
    padding-bottom: .2em;
}
.shops .name-box>.icons {
    position: absolute;
    right: 0;
    bottom: -36px;
}
.shops .name-box>.icons img {
    width: 22px;
}
.shops .shop ul.info {
    padding: 0;
    margin: 0;
    list-style: none;
}
.shops .shop ul.info >li:first-child {
    padding: .5em 0;
}
.shops .shop>p {
    opacity: .4;
    margin: .5em 0;
}

.foods>div {
    margin-bottom: 15px;
}
.foods>div:first-child {
    margin-bottom: 0;
}
.foods .p-name {
    padding-top: .5em;
   border-top: 5px solid #f36f21; 
    font-weight: 700;
}
.foods .p-name>h3 {
    font-size: 18px;
    padding: 0;
    margin: 0;
}
.foods .p-name small {
    font-size: 14px;
}
.foods .p-info {
    opacity: .8;
}

.package {
    font-size: 14px;
}
.package h4 {
    font-weight: 400px;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: .5em;
}
.package .package-info {
    margin-top: -4em;
}
.package .package-info .space {
    font-weight: 100;
    display: inline-block;
    transform: scaleX(1.8);
    transform: scaleY(1.2);
    margin: 0 .5em;
}

.contact {
    padding-top: 30px;
}
.contact .container {
    max-width: 830px;
}
.contact ol {
    line-height: 1.6em;
    margin-bottom: 2em;
    padding-left: 20px;
}
.contact ol>li {
    margin: .6em 0;
}

.form-control, .form-control:focus {
    border-radius: 0;
    background-color: #000;
    border-color: #464646;
    color: #fff;
}
.btn-reg {
    background-color: transparent;
    color: #fff;
    padding-left: .5em; padding-right: .5em;
}
.btn-reg>i {
    font-size: 19px;
    transform: translateY(4px);
}

.news {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
}
.news-box {
    border-bottom: 1px solid #f36f21;
}
.news-box .news-title {
    font-size: 22px;
    line-height: 1.5em;
}
.news .more-news {
    position: absolute;
    right: 15px;
    top: -70px;
}

.news .text-section {
    color: rgba(255,255,255,.8);
}
.news-box .post-date, .news-box .text-section {
    font-size: 14px;
}
.news .more-news>a {
    display: inline-block;
    padding-top: 8px;
    background: url(../images/arrow-long.svg) left top no-repeat;
    background-size: 138px 8px;
}

#privacy .container {
    background-color: #fff;
    height: 600px;
    overflow-y: auto;
    color: #030303;
    padding: 0;
}
#privacy .container h2 {
    margin-bottom: 60px;
}

#privacy .container .close {
    width: 48px;
    height: 48px;
}
.fancybox-slide>* {
    margin: 0;
}

.m-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #4a211b;
    z-index: 100;
}
.m-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 50% 50%;
}
.m-nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.m-nav ul li a {
    color: #ad3703;
    font-size: 16px;
    &>img {
        width: 28px;
        margin-right: .5rem;
    }
}
.m-nav ul li span.navicon {
    font-size: 2rem;
}
@media screen and (max-width: 767px) {
    .banner .container {
        width: 100%;
    }
}


.menu-nav {
    &>.container {
        width: 1440px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
    .menu-cat {
        display: inline-grid;
        grid-template-columns: 151px 1fr 151px;
        
        &>div.selected {
            background-color: #f36f21;
        }
        &>div:first-child, &>div:last-child {
            display: flex;
            align-items: center;
        }

        .banner &>ul {
            @media screen and (min-width: 576px) {
                padding-left: 1.5em;
                padding-right: 1.5em;
            }
        }

    }
}


.text-shadow {
    text-shadow: 0 1px 2px rgba(0,0,0,.8);
    text-align: center;
    line-height: 1.8 !important;
    margin-top: .75rem;

    // &.en {
    //     font-family: 'Abyssinica SIL', serif;
    // }
}

.home-menu-title {
    max-width: 290px;
}