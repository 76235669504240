@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4kklne');
  src:  url('fonts/icomoon.eot?4kklne#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4kklne') format('truetype'),
    url('fonts/icomoon.woff?4kklne') format('woff'),
    url('fonts/icomoon.svg?4kklne#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.navicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-i-01:before {
  content: "\e900";
  color: #b1603e;
}
.icon-i-02:before {
  content: "\e901";
  color: #b1603e;
}
.icon-i-03:before {
  content: "\e902";
  color: #b1603e;
}
.icon-i-04:before {
  content: "\e903";
  color: #b1603e;
}
.icon-i-05:before {
  content: "\e904";
  color: #b1603e;
}
