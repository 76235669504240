@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(../assets/fancybox/jquery.fancybox.min.css);
@import url(../assets/pace/pace.css);
@import url(../assets/slick/slick.css);
@import url(../assets/slick/slick-theme.css);
@import url(../assets/vegas/vegas.css);
@import url(../assets/timeliner/jquery-timeliner.css);
.timeline {
  padding-bottom: 6em;
  padding-top: 3em;
  position: relative;
}

.timeline:before, .timeline:after {
  content: " ";
  display: table;
}

.timeline:after {
  clear: both;
}

.timeline:before, .timeline:after {
  content: " ";
  display: table;
}

.timeline:after {
  clear: both;
}

.timeline-path {
  bottom: 0;
  left: 50%;
  background-color: #f36f21;
  margin-left: -0.125em;
  position: absolute;
  top: 0;
  width: 0.25em;
  z-index: 1;
}

.timeline-path:before, .timeline-path:after {
  width: 1em;
  height: 1em;
  background-color: #f36f21;
  border-radius: 50%;
  content: "";
  left: 50%;
  margin-left: -0.5em;
  position: absolute;
  z-index: 3;
}

.timeline-path:before {
  top: -1em;
}

.timeline-path:after {
  bottom: -1em;
}

.timeline-item-node {
  width: 10%;
  height: 1px;
  background-color: #f36f21;
  position: absolute;
  top: 25px;
}

.timeline-item-node:after {
  -webkit-box-shadow: 0 0 0 4px #000;
  box-shadow: 0 0 0 4px #000;
  width: 1em;
  height: 1em;
  background-color: #000;
  border: 1px solid #f36f21;
  border-radius: 50%;
  content: "";
  margin-top: -0.5em;
  position: absolute;
  z-index: 3;
}

.timeline-item-inner {
  background-color: #000;
  border-radius: 0.15em;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 0 rgba(0, 0, 0, 0.04);
  color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 1em;
  border: 1px solid #f36f21;
}

.timeline-item-inner:hover, .timeline-item-inner:focus {
  outline: none;
  text-decoration: none;
}

.timeline-item-inner:hover h3, .timeline-item-inner:focus h3 {
  text-decoration: underline;
}

.timeline-item {
  word-wrap: break-word;
}

@media (max-width: 767px) {
  .timeline {
    padding-top: 0;
    padding-bottom: 0;
  }
  .timeline-path {
    left: 5px;
    display: none;
  }
  .timeline-item {
    margin-top: 15px;
    position: relative;
    float: right;
    /*margin-left: 5px;
    padding-left: 20px;*/
    word-wrap: break-word;
  }
  .timeline-item .timeline-item-node {
    left: 0;
    width: 20px;
    display: none;
  }
  .timeline-item .timeline-item-node:after {
    margin-left: -0.5em;
  }
  .timeline-item-inner {
    border: none;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .timeline-item--offset {
    float: right;
    height: 6em;
    width: 40%;
  }
  .timeline-item {
    margin-top: 3em;
    position: relative;
    width: 50%;
    overflow: hidden;
  }
  .timeline-item--odd {
    clear: left;
    float: left;
    padding-right: 5%;
  }
  .timeline-item--odd .timeline-item-node {
    right: 0;
  }
  .timeline-item--odd .timeline-item-node:after {
    margin-right: -0.5em;
    right: 0;
  }
  .timeline-item--even {
    clear: right;
    float: right;
    padding-left: 5%;
  }
  .timeline-item--even .timeline-item-node {
    left: 0;
  }
  .timeline-item--even .timeline-item-node:after {
    margin-left: -0.5em;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?4kklne");
  src: url("fonts/icomoon.eot?4kklne#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?4kklne") format("truetype"), url("fonts/icomoon.woff?4kklne") format("woff"), url("fonts/icomoon.svg?4kklne#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.navicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-i-01:before {
  content: "\e900";
  color: #b1603e;
}

.icon-i-02:before {
  content: "\e901";
  color: #b1603e;
}

.icon-i-03:before {
  content: "\e902";
  color: #b1603e;
}

.icon-i-04:before {
  content: "\e903";
  color: #b1603e;
}

.icon-i-05:before {
  content: "\e904";
  color: #b1603e;
}

a, a:hover, a:focus {
  color: #fff;
  text-decoration: none;
}

body {
  font-family: "Noto Sans TC", sans-serif;
  background-color: #000;
  color: #fff;
  position: relative;
}

.color-orange {
  color: #f36f21 !important;
}

.color-gold {
  color: #b3903b !important;
}

.btn {
  border-radius: 0;
  padding: 0.4rem 2.5rem;
}

.btn.focus, .btn:focus {
  box-shadow: none;
}

.btn-primary, .btn-primary:hover {
  color: #000;
  background-color: #f36f21;
  border-color: #f36f21;
}

.btn-outline-primary {
  border-color: #fff;
  color: #fff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-primary:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.font-w300 {
  font-weight: 300;
}

/* header & Nav */
header {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 900;
}

header .logo {
  height: 48px;
  margin-left: 16px;
  margin-top: 15px;
}

header .lang {
  font-size: 13px;
  color: #f36f21;
}

header .lang span {
  display: inline-block;
  margin: 0 0.5em;
}

header > div {
  text-align: right;
  padding-right: 20px;
}

header nav.main-nav {
  background-color: #000000;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  border-top: 6px solid #f36f21;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

header nav.main-nav .logo-box > img {
  display: block;
}

header nav.main-nav .logo-box .logo {
  width: 114px;
  margin-bottom: 4px;
}

header nav.main-nav .logo-box .logo2 {
  width: 90px;
  margin-left: 4px;
}

header nav.main-nav .menu-close-xs {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 34px;
  opacity: 1;
}

header nav.main-nav .close > img {
  width: 34px;
  height: 34px;
}

header nav.main-nav > div {
  margin: auto;
  box-sizing: border-box;
  padding: 30px 20px;
}

header nav.main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 0.5em;
}

header nav.main-nav ul .li-lang > a {
  border-top: 2px solid #f36f21;
  margin-top: 1em;
}

header nav.main-nav ul > li > a {
  padding: 0.25em 0;
  font-size: 22px;
  display: inline-block;
}

header nav.main-nav ul > li .submenu > a {
  opacity: 0.5;
  display: block;
  font-size: 18px;
  padding: 0.25em 0;
}

header .menu-xs {
  width: 60px;
  height: 50px;
  display: block;
  margin: 10px 15px 0 0;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 11px;
}

header .menu-xs > img {
  width: 100%;
  height: 100%;
}

header#navbar .logo {
  height: 76px;
}

footer {
  position: relative;
}

footer > div {
  padding-top: 30px;
  padding-bottom: 30px;
}

footer .social-networks {
  margin-bottom: 2.5em;
}

footer .copyright a {
  opacity: 0.8;
  font-size: 14px;
}

footer .copyright span.space {
  opacity: 0.8;
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  transform: translateY(-0.15em);
}

.gotop {
  position: fixed;
  z-index: 90;
  display: block;
  right: 10px;
  bottom: 50px;
  width: 40px;
  height: 100px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.gotop.show {
  opacity: 1;
}

/* Section */
section {
  position: relative;
}

img.arrow {
  width: 40px;
}

.back img.arrow {
  opacity: 0.8;
  width: 32px;
  transform: translateY(-3px) scaleX(-1);
}

h1.main-title {
  font-weight: 400;
  text-align: center;
  display: block;
  margin: 0;
  padding: 1rem 0;
  margin-bottom: 0.5em;
  width: 100%;
  font-size: 2rem;
}

section.home {
  width: 100%;
  height: 100vh !important;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

section.home .lab {
  position: absolute;
  display: block;
  right: 0;
  bottom: 80px;
}

section.home .logo-box {
  position: absolute;
  left: 12px;
  right: 12px;
  top: 12px;
}

section.home .logo-box > img {
  display: block;
}

section.home .logo-box .logo {
  width: 82px;
  margin-bottom: 6px;
}

section.home .logo-box .logo2 {
  width: 290px;
  margin: auto;
  margin-top: 50%;
}

.marquee-box {
  margin: auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.marquee-box > div {
  width: 80%;
  box-sizing: border-box;
}

.marquee-box > .title {
  background-color: #f36f21;
  text-align: center;
  color: #fff;
  width: 20%;
  border-radius: 18px 0 0 18px;
  height: 36px;
  line-height: 36px;
}

.marquee-box > div:last-child {
  border-radius: 0 18px 18px 0;
}

.marquee {
  overflow: hidden;
  background: #fff;
  padding: 0 1em;
  height: 36px;
  width: 200px;
}

.marquee a {
  display: table-cell;
  height: 36px;
  vertical-align: middle;
}

.marquee a {
  color: #000;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -38px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 38px;
  background-color: transparent;
  border: none;
  text-indent: -999999px;
  outline: 0;
  padding: 0;
  z-index: 90;
}

.slick-prev {
  left: 5px;
  background-image: url(../images/prev.svg) !important;
}

.slick-next {
  right: 5px;
  background-image: url(../images/next.svg) !important;
}

#menu .v-line {
  height: 4em;
  width: 1px;
  overflow: hidden;
  background-color: #f36f21;
}

#menu .menu-btn > p {
  line-height: 2em;
  letter-spacing: 0.2em;
}

#menu .icon {
  display: block;
  width: 24px;
  height: 24px;
}

#menu .menu-left, #menu .menu-right {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#menu .show {
  opacity: 1;
  z-index: 20;
}

#menu .menu-left .bg {
  background: url(../images/menu-left2.jpg) #000 no-repeat;
}

#menu .menu-right .bg {
  background: url(../images/menu-right2.jpg) #000 no-repeat;
}

#menu .menu-box .type-name {
  max-height: 106px;
  margin-bottom: 2rem;
}
#menu .menu-box .bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  z-index: 0;
}

#menu svg {
  display: block;
  height: auto;
  width: 100%;
}

#menu .go-box {
  width: 20%;
  box-sizing: border-box;
  height: 100%;
  background-color: #FF6517;
  position: relative;
  padding: 0 25px;
  padding-bottom: 45px;
  z-index: 5;
}

#menu .go-box > * {
  position: relative;
  z-index: 5;
}

#menu .go-box:after {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  width: 15%;
  background-color: #FF6517;
  z-index: 0;
}

#menu .go-box.float-left {
  transform: translateX(-120%);
}

#menu .menu-right .bg {
  opacity: 0;
  transform: translateX(10%);
}

#menu .go-box.float-right {
  transform: translateX(120%);
}

#menu .menu-left .bg {
  opacity: 0;
  transform: translateX(-10%);
}

#menu .menu-box {
  overflow: hidden;
  background-color: #000;
}

#menu .menu-box.show .go-box,
#menu .menu-box.show .bg {
  opacity: 1;
  transform: translateX(0);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transition-delay: 0.4s; /* Safari */
  transition-delay: 0.4s;
}

#menu .go-box.float-left:after {
  transform-origin: right top;
  transform: skewX(2.3deg);
  right: 0;
}

#menu .go-box.float-right:after {
  transform-origin: left top;
  transform: skewX(-2.3deg);
  left: 0;
}

#menu .icons-box {
  width: 80%;
  box-sizing: border-box;
  height: 100%;
  z-index: 5;
  position: relative;
}

#menu .icons-box > div {
  width: 100%;
  padding: 35px 0 15px 0;
  max-width: 488px;
  border: 12px solid #FF6517;
  box-sizing: border-box;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

#menu .icons-box h1 {
  font-size: 56px;
}

#menu .icons-box h6 {
  margin-bottom: 2em;
}

#menu .icons-box > div > p > a, #menu .icons-box > div > p > span {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  color: #ff853d;
  margin: 0 1em;
}

#menu .icons-box > div > p > a > img, #menu .icons-box > div > p > span > img {
  margin: auto;
  width: 52px;
  height: 52px;
}

.navpage .mobile-btns {
  overflow: hidden;
}

.navpage .mobile-btns > a {
  box-sizing: border-box;
  float: left;
  width: 50%;
}

#mMenu {
  margin-bottom: 15px;
}

#mMenu .slick-slide img {
  width: 100%;
}

#mMenu .slick-slide p {
  padding: 0.4em 0 0.4em 0;
  border-top: 1px solid #f36f21;
  font-size: 18px;
  margin: 0;
  margin-top: 0.6em;
  line-height: 1.2em;
}

#news {
  background: url(../images/news-bg.jpg) no-repeat;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 60px;
}

#news h1.main-title {
  padding-left: 0.25em;
  letter-spacing: 0.25em;
}

#news .row .row {
  position: relative;
}

#news .row > div {
  position: static;
}

#news .stamp {
  position: absolute;
  right: 15px;
  bottom: 65px;
  z-index: 30;
  max-width: 92px;
}

#news .new, #news .new .content {
  width: 100%;
}

#news .photo-box {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

#news .photo-box > .photo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 11;
  padding-right: 2.5%;
}

#news .more, #menu .more {
  margin: auto;
  margin-top: 1em;
  font-size: 16px;
  letter-spacing: 0.25em;
  margin-bottom: 30px;
}

#news .more > img, #menu .more > img {
  vertical-align: middle;
  transform: translateY(-3px);
}

#news .news-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

#job {
  margin-top: -41%;
  pointer-events: none;
}
@media screen and (min-width: 480px) {
  #job {
    margin-top: -30%;
  }
}
@media screen and (min-width: 768px) {
  #job {
    margin-top: -36%;
  }
}
@media screen and (min-width: 998px) {
  #job {
    margin-top: -26%;
  }
}
@media screen and (min-width: 1200px) {
  #job {
    margin-top: -18%;
  }
}
@media screen and (min-width: 1400px) {
  #job {
    margin-top: -13%;
  }
}
@media screen and (min-width: 1920px) {
  #job {
    margin-top: -8%;
  }
}

svg.join-bg {
  display: block;
  width: 100%;
  height: 547px;
}
@media screen and (min-width: 1440px) {
  svg.join-bg {
    height: 680px;
  }
}

.join {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  padding-top: 35px;
  display: flex;
  align-items: center;
}

.join > div {
  padding-top: 30px;
  width: 100%;
}

.join > div .mobile-join-text {
  width: 100%;
}

.join > div > div {
  width: 272px;
  margin: auto;
  pointer-events: auto;
}

.join > div > div > a {
  display: block;
  border: 1px solid #fff;
  margin: auto;
  margin-top: 12px;
  color: #fff;
  padding: 0.4em 0;
  text-align: center;
  max-width: 250px;
}

#job-content {
  background: #f36f21;
  padding: 12px;
}

#job-content > div {
  border: 4px solid #000;
  padding: 15px;
}

#about {
  background-color: #272727;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 768px) {
  #about .tab-contents {
    min-height: 732px;
  }
}

#about ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#about ul li {
  margin-bottom: 1px;
  position: relative;
}

#about ul li a.mbtn {
  display: block;
  background-color: #d7d7d7;
  padding-right: 48px;
  position: relative;
}

#about ul li > a.mbtn > img {
  margin: auto;
  display: block;
}

#about ul li a.mbtn + div {
  display: none;
  padding: 20px 15px;
  line-height: 1.6em;
  overflow: hidden;
}

#about ul li a.mbtn:after {
  content: "";
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  background: url(../images/arrow-v.svg) no-repeat;
  background-size: 100% 100%;
}

#about ul li a.mbtn.selected:after {
  transform: rotate(180deg);
}

#about .mtab-content1 {
  background: url(../images/tab1m-bg.jpg) center bottom #000 no-repeat;
  background-size: 100% auto;
  padding-bottom: 70% !important;
}

#about .mtab-content2 {
  background: url(../images/tab2m-bg.jpg) no-repeat;
  background-size: cover;
  padding-bottom: 0 !important;
}

#about .mtab-content3 {
  background: #14aee6 no-repeat;
  padding-bottom: 0 !important;
}

#about .mtab-content3 p:last-child {
  margin-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
}

#about .tab-cover > div {
  padding: 30px 15px;
  text-align: left;
}

#about .tab-cover > div img {
  max-width: 112px;
  margin: auto;
  display: block;
}

.banner {
  border-bottom: 1px solid #f36f21;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .banner > img {
    min-width: 930px !important;
  }
}
@media screen and (max-width: 512px) {
  .banner > img {
    min-width: 600px !important;
  }
}

.banner > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.banner h1 {
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.2em;
}
.banner h1 img {
  max-width: 280px;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 1100px) {
  .banner h1 img {
    margin-top: 64px;
    max-width: 220px;
  }
}
@media screen and (max-width: 512px) {
  .banner h1 img {
    max-width: 180px;
  }
}

h2.page-title {
  font-size: 22px;
  font-weight: 400;
  opacity: 0.4;
  padding: 1.5em 0 1.5em 0.2em;
  letter-spacing: 0.2em;
}

h2.page-title .space {
  font-weight: 100;
  margin-top: 1em;
}

h2.page-title-w {
  opacity: 1;
}

.shops .shop {
  font-size: 14px;
  margin-bottom: 30px;
}

.shops .name-box {
  border-left: 5px solid #f36f21;
  padding-left: 6px;
  position: relative;
}

.shops .name-box > h3 {
  font-size: 18px;
  padding-bottom: 0.2em;
}

.shops .name-box > .icons {
  position: absolute;
  right: 0;
  bottom: -36px;
}

.shops .name-box > .icons img {
  width: 22px;
}

.shops .shop ul.info {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shops .shop ul.info > li:first-child {
  padding: 0.5em 0;
}

.shops .shop > p {
  opacity: 0.4;
  margin: 0.5em 0;
}

.foods > div {
  margin-bottom: 15px;
}

.foods > div:first-child {
  margin-bottom: 0;
}

.foods .p-name {
  padding-top: 0.5em;
  border-top: 5px solid #f36f21;
  font-weight: 700;
}

.foods .p-name > h3 {
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.foods .p-name small {
  font-size: 14px;
}

.foods .p-info {
  opacity: 0.8;
}

.package {
  font-size: 14px;
}

.package h4 {
  font-weight: 400px;
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 0.5em;
}

.package .package-info {
  margin-top: -4em;
}

.package .package-info .space {
  font-weight: 100;
  display: inline-block;
  transform: scaleX(1.8);
  transform: scaleY(1.2);
  margin: 0 0.5em;
}

.contact {
  padding-top: 30px;
}

.contact .container {
  max-width: 830px;
}

.contact ol {
  line-height: 1.6em;
  margin-bottom: 2em;
  padding-left: 20px;
}

.contact ol > li {
  margin: 0.6em 0;
}

.form-control, .form-control:focus {
  border-radius: 0;
  background-color: #000;
  border-color: #464646;
  color: #fff;
}

.btn-reg {
  background-color: transparent;
  color: #fff;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.btn-reg > i {
  font-size: 19px;
  transform: translateY(4px);
}

.news {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.news-box {
  border-bottom: 1px solid #f36f21;
}

.news-box .news-title {
  font-size: 22px;
  line-height: 1.5em;
}

.news .more-news {
  position: absolute;
  right: 15px;
  top: -70px;
}

.news .text-section {
  color: rgba(255, 255, 255, 0.8);
}

.news-box .post-date, .news-box .text-section {
  font-size: 14px;
}

.news .more-news > a {
  display: inline-block;
  padding-top: 8px;
  background: url(../images/arrow-long.svg) left top no-repeat;
  background-size: 138px 8px;
}

#privacy .container {
  background-color: #fff;
  height: 600px;
  overflow-y: auto;
  color: #030303;
  padding: 0;
}

#privacy .container h2 {
  margin-bottom: 60px;
}

#privacy .container .close {
  width: 48px;
  height: 48px;
}

.fancybox-slide > * {
  margin: 0;
}

.m-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4a211b;
  z-index: 100;
}

.m-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 50% 50%;
}

.m-nav ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.m-nav ul li a {
  color: #ad3703;
  font-size: 16px;
}
.m-nav ul li a > img {
  width: 28px;
  margin-right: 0.5rem;
}

.m-nav ul li span.navicon {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .banner .container {
    width: 100%;
  }
}
.menu-nav > .container {
  width: 1440px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.menu-nav .menu-cat {
  display: inline-grid;
  grid-template-columns: 151px 1fr 151px;
}
.menu-nav .menu-cat > div.selected {
  background-color: #f36f21;
}
.menu-nav .menu-cat > div:first-child, .menu-nav .menu-cat > div:last-child {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .banner .menu-nav .menu-cat > ul {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 1.8 !important;
  margin-top: 0.75rem;
}

.home-menu-title {
  max-width: 290px;
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  footer {
    background: url(../images/bg-foot.jpg) no-repeat;
    background-size: cover;
  }
  footer .social-networks {
    margin-bottom: 0;
  }
  .gotop {
    bottom: 90px;
    right: 25px;
  }
  section.home .lab {
    right: 40px;
    bottom: 15px;
  }
  #news {
    padding-bottom: 90px;
  }
  #about ul li a.mbtn + div {
    display: none !important;
  }
  #job-content > div {
    padding: 30px;
  }
  .tab-contents {
    overflow: hidden;
    position: relative;
  }
  .tab-contents > div {
    opacity: 0;
    transform: scale(0.95);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    box-sizing: border-box;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .tab-contents > div.over {
    opacity: 1;
    z-index: 10;
    transform: scale(1);
  }
  .tab-contents > div > div {
    width: 100%;
    max-width: 990px;
    float: right;
    box-sizing: border-box;
  }
  .tab-contents > div:first-child {
    display: block;
  }
  .tab-contents .tab-cover {
    background-color: #272727;
  }
  .tab-contents .tab-cover img {
    margin-bottom: 60px;
  }
  .tab-contents .tab-cover p {
    max-width: 560px;
    margin: auto;
  }
  .tab-contents .tab-content > div {
    padding-left: 30px;
    padding-right: 30px;
  }
  .tab-contents .tab-content p {
    max-width: 480px;
    margin: 1em 0;
  }
  .tab-contents #tab1 {
    background: url(../images/tab1-bg.jpg) no-repeat;
    background-size: cover;
  }
  .tab-contents #tab1 p:last-child {
    margin-bottom: 70px;
  }
  .tab-contents #tab2 {
    background: url(../images/tab2-bg.jpg) no-repeat;
    background-size: cover;
  }
  .tab-contents #tab3 {
    background: url(../images/tab3-bg.jpg) no-repeat;
    background-size: cover;
  }
  .join {
    display: flex;
    align-items: center;
  }
  #about ul > li {
    margin-bottom: 0;
    overflow: hidden;
  }
  #about ul > li > a {
    display: block;
    max-width: 360px;
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
  }
  #about ul > li > a > img {
    display: block;
    /*border-bottom: 1px solid #6b6b6b;*/
    position: relative;
    z-index: 10;
  }
  #about ul > li > a > img[data-over] {
    display: none;
  }
  #about ul > li:last-child > a > img {
    border-bottom: none;
  }
  #about ul > li > a.selected > img {
    border-color: transparent;
  }
  #about ul > li > a:after {
    content: "";
    display: block;
    width: 2000em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: transparent;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  #about ul > li:nth-child(1) > a.selected:after, #about ul > li:nth-child(1) > a:hover:after {
    background-color: #000;
  }
  #about ul > li:nth-child(1) > a.selected > img {
    display: none;
  }
  #about ul > li:nth-child(1) > a.selected > img[data-over] {
    display: block;
  }
  #about ul > li:nth-child(2) > a.selected:after, #about ul > li:nth-child(2) > a:hover:after {
    background-color: #024288;
  }
  #about ul > li:nth-child(2) > a:hover > img {
    display: none;
  }
  #about ul > li:nth-child(2) > a:hover > img[data-over] {
    display: block;
  }
  #about ul > li:nth-child(3) > a.selected:after, #about ul > li:nth-child(3) > a:hover:after {
    background-color: #1eb9ee;
  }
  .banner h1 small {
    display: block;
    font-size: 14px;
    margin: 1em 0;
    letter-spacing: 0;
  }
  .banner .menu-nav {
    border-top: 1px solid #f36f21;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    top: auto;
  }
  .banner .menu-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
  }
  .banner .menu-nav ul > li {
    float: left;
  }
  .banner .menu-nav a {
    display: block;
    padding: 0.5em 1em;
    position: relative;
    font-size: 18px;
  }
  .banner .menu-nav .float-right a:hover, .banner .menu-nav .float-left li:first-child a:hover {
    color: #ad3703;
  }
  .banner .menu-nav a.icon {
    padding-left: 56px;
  }
  .banner .menu-nav a.icon img {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 17px;
    top: 50%;
    margin-top: -13px;
  }
  .banner .menu-nav a.selected {
    background-color: #f36f21;
  }
  .banner .menu-nav a.selected:hover {
    color: #AD3703;
  }
  .shops .name-box > h3 {
    padding-bottom: 0;
  }
  .shops .name-box > .icons {
    position: static;
  }
  .shops .name-box > .icons span {
    display: inline-block;
    margin-right: 0.5em;
  }
  .shops .name-box a.color-orange:hover {
    color: #fff !important;
  }
  #menu .icons-box > div > p > a:hover {
    color: #fff;
  }
  .contact .btn-primary {
    color: #fff;
    cursor: pointer;
  }
  .contact .btn-primary:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
}
/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  a:hover {
    color: #f36f21;
  }
  .btn-primary:hover {
    color: #fff;
  }
  header {
    padding: 15px 0;
    padding-bottom: 38px;
  }
  header nav.main-nav ul li {
    float: left;
    position: relative;
  }
  header nav ul li:after {
    content: "";
    display: inline-block;
    border-top: 1px solid #f36f21;
    width: 15px;
    margin: 0 5px;
    height: 7px;
  }
  header nav ul li.no-line:after {
    display: none;
  }
  header > div, header nav {
    padding-right: 20px;
  }
  header nav.main-nav ul li a {
    display: inline-block;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
    padding: 0;
    margin: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  header nav.main-nav ul li a:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    color: #f36f21;
  }
  header nav.main-nav {
    background-color: transparent;
    position: static;
    border-top: none;
  }
  header nav.main-nav > div {
    max-width: 100%;
    padding: 0;
  }
  header nav.main-nav ul {
    padding-top: 0;
  }
  header nav.main-nav ul a {
    opacity: 1;
  }
  header nav.main-nav ul > li .submenu > a {
    font-size: 15px;
    display: block;
    opacity: 1;
    margin: 0.25rem 0;
  }
  header nav.main-nav ul > li .submenu {
    display: none;
    position: absolute;
    white-space: nowrap;
    width: 140px;
    left: 50%;
    margin-left: -103px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 0;
  }
  header nav.main-nav ul > li .submenu.show {
    display: block;
  }
  header nav.main-nav ul > li .submenu > span {
    font-weight: 300;
    font-size: 13px;
    color: #f36f21;
    display: block;
    margin: 0 0.5em;
    transform: translateY(-1px);
    display: inline-block !important;
  }
  header .logo {
    position: absolute;
    left: 20px;
    height: 66px;
    margin-left: 0;
    margin-top: 0;
  }
  header nav ul li:after {
    width: 25px;
  }
  header.black-bg {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  footer a:hover {
    opacity: 1;
    color: #fff;
  }
  footer .social-networks a:hover {
    opacity: 0.8;
  }
  section.home .vegas-wrapper > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 125px;
  }
  section.home .logo-box .logo2 {
    width: auto;
    max-width: 444px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: static;
    margin-top: 80px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .marquee-box {
    max-width: 378px;
  }
  #news .photo-box > .photo {
    padding-right: 7%;
  }
  #menu .navpage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
  #news {
    padding-top: 110px;
    padding-bottom: 200px;
  }
  #news .row {
    overflow: hidden;
    padding-bottom: 45px;
  }
  #news .row > div {
    position: relative;
  }
  #news .content {
    width: 100%;
  }
  #news .photo-box {
    position: relative;
  }
  #news .photo-box img.bg {
    position: relative;
    z-index: 10;
  }
  #news .photo-box .slogan {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    z-index: 20;
    position: relative;
    font-size: 18px;
    letter-spacing: 0.2em;
    padding-top: 0.2em;
  }
  #news .photo-box .slogan:after, #news .photo-box .slogan:before {
    content: "";
    display: block;
    position: absolute;
    height: 2em;
    width: 50%;
    border-color: #e56924;
    border-style: solid;
    border-width: 0;
  }
  #news .photo-box .slogan:before {
    right: -1.5em;
    top: -0.5em;
    border-right-width: 2px;
    border-top-width: 2px;
  }
  #news .photo-box .slogan:after {
    left: -1.5em;
    bottom: -0.5em;
    border-left-width: 2px;
    border-bottom-width: 2px;
  }
  #news .photo-box .slogan > span {
    position: relative;
    display: block;
  }
  #news .photo-box .slogan > span:after, #news .photo-box .slogan > span:before {
    content: "";
    display: block;
    background-color: #e56924;
    width: 2px;
    height: 1000em;
    left: 47%;
    position: absolute;
  }
  #news .photo-box .slogan > span:after {
    top: 110%;
  }
  #news .photo-box .slogan > span:before {
    bottom: 110%;
  }
  #news .new {
    transform: translateY(45px);
    float: right;
  }
  #news .new img.bg {
    position: relative;
    display: block;
    z-index: 5;
  }
  #news .new > div, #news .new .stamp {
    position: absolute;
    z-index: 100;
  }
  #news .more {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
  #news .stamp {
    right: 30px;
    bottom: 30px;
  }
  #news .new > div, #news .new .stamp {
    position: absolute;
    z-index: 100;
  }
  #about .tab-cover > div img {
    max-width: 100%;
    margin-bottom: 60px;
  }
  .tab-contents .tab-cover p {
    text-align: center;
  }
  .contact {
    background: url(../images/contact-bg.jpg) center bottom no-repeat;
    padding-bottom: 700px;
  }
  .contact form {
    max-width: 580px;
  }
  .banner h1 {
    font-size: 58px;
    margin-bottom: 30px;
  }
  h2.page-title {
    font-size: 26px;
  }
  .shops-out {
    max-width: 990px;
  }
  .shops .shop {
    font-size: 15px;
  }
  .shops .name-box > h3 {
    font-size: 20px;
  }
  .package .package-info {
    margin-top: 0;
    margin-bottom: 62px;
  }
  .package .package-info h4,
.package .package-info p {
    padding-left: 0;
    padding-right: 0;
  }
  .package h4 {
    font-size: 20px;
  }
  .package {
    font-size: 18px;
    line-height: 1.5em;
  }
  .col-form-label {
    text-align: right;
  }
  .contact {
    padding-top: 60px;
  }
  .contact ol {
    padding-left: 40px;
  }
  .news-item {
    max-width: 840px;
    position: relative;
    z-index: 10;
  }
  .news .more-news {
    position: static;
    margin-bottom: 30px;
  }
  .news-item .post-date {
    width: 108px;
    box-sizing: border-box;
    border: 1px solid #f36f21;
    border-radius: 4px;
    font-size: 15px;
    padding: 4px;
    margin-top: 30px;
  }
  .news-item .post-date > p {
    margin: 0;
    line-height: 1;
  }
  .news-item .post-date .month {
    background-color: #f36f21;
    color: #000;
    font-size: 18px;
    border-radius: 4px;
    padding: 4px 0;
  }
  .news-item .post-date .day {
    font-size: 56px;
    font-weight: 900;
    margin-bottom: 15px;
  }
  .news-item .post-date .year {
    font-size: 10px;
  }
  .news-item .news-box {
    margin-left: 118px;
    border: 1px solid #f36f21;
    border-radius: 4px;
    padding: 27px 22px;
    background-color: #000;
  }
  .news .news-title {
    font-size: 28px;
    margin-bottom: 0.6em;
  }
  .news .post-date, .news .text-section {
    font-size: 15px;
  }
  .news .back {
    margin-left: 118px;
    margin-top: 15px;
    display: inline-block;
  }
  .menu-btn {
    display: block;
    -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;
  }
  .menu-btn:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    color: #fff;
  }
  .shops .shop ul.info a:hover {
    color: #b8b8b8;
  }
  #privacy .container {
    padding: 45px 60px;
  }
}
/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  header nav ul li:after {
    width: 55px;
  }
  header .lang {
    margin-bottom: 0.5em;
  }
  header .logo {
    height: auto;
  }
  .marquee-box {
    max-width: 460px;
  }
  .tab-contents .tab-content > div {
    padding-left: 90px;
  }
  #menu .where-box {
    flex-basis: 392px;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  .marquee-box {
    max-width: 680px;
  }
}