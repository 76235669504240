.timeline {
    padding-bottom: 6em;
    padding-top: 3em;
    position: relative;
}

.timeline:before, .timeline:after {
    content: " ";
    display: table;
}

.timeline:after {
    clear: both;
}

.timeline:before, .timeline:after {
    content: " ";
    display: table;
}

.timeline:after {
    clear: both;
}

.timeline-path {
    bottom: 0;
    left: 50%;
    background-color: #f36f21;
    margin-left: -0.125em;
    position: absolute;
    top: 0;
    width: .25em;
    z-index: 1;
}

.timeline-path:before, .timeline-path:after {
    width: 1em;
    height: 1em;
    background-color: #f36f21;
    border-radius: 50%;
    content: "";
    left: 50%;
    margin-left: -0.5em;
    position: absolute;
    z-index: 3;
}

.timeline-path:before {
    top: -1em;
}

.timeline-path:after {
    bottom: -1em;
}

.timeline-item-node {
    width: 10%;
    height: 1px;
    background-color: #f36f21;
    position: absolute;
    top: 25px;
}

.timeline-item-node:after {
    -webkit-box-shadow: 0 0 0 4px #000;
    box-shadow: 0 0 0 4px #000;
    width: 1em;
    height: 1em;
    background-color: #000;
    border: 1px solid #f36f21;
    border-radius: 50%;
    content: "";
    margin-top: -0.5em;
    position: absolute;
    z-index: 3;
}

.timeline-item-inner {
    background-color: #000;
    border-radius: .15em;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 0 rgba(0, 0, 0, 0.04);
    color: rgba(255,255,255,.5);
    display: block;
    padding: 1em;
    border: 1px solid #f36f21;
}

.timeline-item-inner:hover, .timeline-item-inner:focus {
    outline: none;
    text-decoration: none;
}

.timeline-item-inner:hover h3, .timeline-item-inner:focus h3 {
    text-decoration: underline;
}

.timeline-item {
    word-wrap: break-word;
}

@media (max-width: 767px) {
    .timeline {
        padding-top: 0;
        padding-bottom: 0;
    }
    .timeline-path {
        left: 5px;
        display: none;
    }

    .timeline-item {
        margin-top: 15px;
        position: relative;
        float: right;
        /*margin-left: 5px;
        padding-left: 20px;*/
        word-wrap: break-word;
    }

    .timeline-item .timeline-item-node {
        left: 0;
        width: 20px;
        display: none;
    }

    .timeline-item .timeline-item-node:after {
        margin-left: -0.5em;
    }
    
    .timeline-item-inner {
        border: none;
        padding: 0;
    }
}

@media (min-width: 768px) {
    .timeline-item--offset {
        float: right;
        height: 6em;
        width: 40%;
    }

    .timeline-item {
        margin-top: 3em;
        position: relative;
        width: 50%;
        overflow: hidden;
    }

    .timeline-item--odd {
        clear: left;
        float: left;
        padding-right: 5%;
    }

    .timeline-item--odd .timeline-item-node {
        right: 0;
    }

    .timeline-item--odd .timeline-item-node:after {
        margin-right: -0.5em;
        right: 0;
    }

    .timeline-item--even {
        clear: right;
        float: right;
        padding-left: 5%;
    }

    .timeline-item--even .timeline-item-node {
        left: 0;
    }

    .timeline-item--even .timeline-item-node:after {
        margin-left: -0.5em;
    }
}