/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {

    header nav ul li:after {
        width: 55px;
    }
    header .lang {
        margin-bottom: .5em;
    }
    header .logo {
        height: auto;
    }
    .marquee-box {
        max-width: 460px;
    }

    .tab-contents .tab-content>div {
        padding-left: 90px;
    }

    #menu .where-box {
        flex-basis: 392px;
    }

}