/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {

a:hover {
    color: #f36f21;
}

.btn-primary:hover {
    color: #fff;
}

header {
    padding: 15px 0;
    padding-bottom: 38px;
}
header nav.main-nav ul li {
    float: left;
    position: relative;
}
header nav ul li:after {
    content: '';
    display: inline-block;
    border-top: 1px solid #f36f21;
    width: 15px;
    margin:  0 5px;
    height: 7px;
}
header nav ul li.no-line:after {
    display: none;
}

header>div, header nav {
    padding-right: 20px;
}

header nav.main-nav ul li a {
    display: inline-block;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
    padding: 0;
    margin: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
}
header nav.main-nav ul li a:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    color: #f36f21;
}

header nav.main-nav {
    background-color: transparent;
    position: static;
    border-top: none;
}
header nav.main-nav>div {
    max-width: 100%;
    padding: 0;
}
header nav.main-nav ul {
    padding-top: 0;
}
header nav.main-nav ul a {
    opacity: 1;
}
header nav.main-nav ul>li .submenu>a {
    font-size: 15px;
    display: block;
    opacity: 1;
    margin: .25rem 0;
}
header nav.main-nav ul>li .submenu {
    display: none;
    position: absolute;
    white-space: nowrap;
    width: 140px;
    left: 50%;
    margin-left: -103px;
    text-align: center;
    background-color: rgba(0,0,0,.6);
    padding: .5rem 0;
}
header nav.main-nav ul>li .submenu.show {
    display: block;
}
header nav.main-nav ul>li .submenu>span {
    font-weight: 300;
    font-size: 13px;
    color: #f36f21;
    display: block;
    margin: 0 .5em;
    transform: translateY(-1px);
    display: inline-block!important;
}
header .logo {
    position: absolute;
    left: 20px;
    height: 66px;
    margin-left: 0;
    margin-top: 0;
}
header nav ul li:after {
    width: 25px;
}

header.black-bg {
    background-color: rgba(0,0,0,.8);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

footer a:hover {
    opacity: 1;
    color: #fff;
}
footer .social-networks a:hover {
    opacity: .8;
}
section.home .vegas-wrapper>div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 125px;
}

section.home .logo-box .logo2 {
    width: auto;
    max-width: 444px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: static;
    margin-top: 80px;
    margin-top: 0;
    margin-bottom: 0;
}

.marquee-box {
    max-width: 378px;
}

#news .photo-box>.photo {
    padding-right: 7%;
}

#menu .navpage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
}

#news {
    padding-top: 110px;
    padding-bottom: 200px;
}

#news .row {
    overflow: hidden;
    padding-bottom: 45px;
}

#news .row>div {
    position: relative;
}

#news .content {
    width: 100%;
}
#news .photo-box {
    position: relative;
}
#news .photo-box img.bg {
    position: relative;
    z-index: 10;
}

#news .photo-box .slogan {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    z-index: 20;
    position: relative;
    font-size: 18px;
    letter-spacing: .2em;
    padding-top: .2em;
}
#news .photo-box .slogan:after, #news .photo-box .slogan:before {
    content: '';
    display: block;
    position: absolute;
    height: 2em;
    width: 50%;
    border-color: #e56924;
    border-style: solid;
    border-width: 0;
}
#news .photo-box .slogan:before {
    right: -1.5em;
    top: -.5em;
    border-right-width: 2px;
    border-top-width: 2px;
}
#news .photo-box .slogan:after {
    left: -1.5em;
    bottom: -.5em;
    border-left-width: 2px;
    border-bottom-width: 2px;
}
#news .photo-box .slogan>span {
    position: relative;
    display: block;
}
#news .photo-box .slogan>span:after, #news .photo-box .slogan>span:before {
    content: '';
    display: block;
    background-color: #e56924;
    width: 2px;
    height: 1000em;
    left: 47%;
    position: absolute;
}
#news .photo-box .slogan>span:after {
    top: 110%;
}
#news .photo-box .slogan>span:before {
    bottom: 110%;
}

#news .new {
    transform: translateY(45px);
    float: right;
}
#news .new img.bg {
    position: relative;
    display: block;
    z-index: 5;
}
#news .new>div, #news .new .stamp {
    position: absolute;
    z-index: 100;
}


#news .more {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
}
#news .stamp {
    right: 30px;
    bottom: 30px;
}
#news .new>div, #news .new .stamp {
    position: absolute;
    z-index: 100;
}

#about .tab-cover>div img {
    max-width: 100%;
    margin-bottom: 60px;
}
.tab-contents .tab-cover p {
    text-align: center;
}

.contact {
    background: url(../images/contact-bg.jpg) center bottom no-repeat;
    padding-bottom: 700px;
}

.contact form {
    max-width: 580px;
}

.banner h1 {
    font-size: 58px;
    margin-bottom: 30px;
}

h2.page-title {
    font-size: 26px;
}

.shops-out {
    max-width: 990px;
}
.shops .shop {
    font-size: 15px;
}
.shops .name-box>h3 {
    font-size: 20px;
}

.package .package-info {
    margin-top: 0;
    margin-bottom: 62px;
}
.package .package-info h4,
.package .package-info p {
    padding-left: 0;
    padding-right: 0;
}
.package h4 {
    font-size: 20px;
}
.package {
    font-size: 18px;
    line-height: 1.5em;
}

.col-form-label {
    text-align: right;
}

.contact {
    padding-top: 60px;
}
.contact ol {
    padding-left: 40px;
}

.news-item {
    max-width: 840px;
    position: relative;
    z-index: 10;
}
.news .more-news {
    position: static;
    margin-bottom: 30px;
}
.news-item .post-date {
    width: 108px;
    box-sizing: border-box;
    border: 1px solid #f36f21;
    border-radius: 4px;
    font-size: 15px;
    padding: 4px;
    margin-top: 30px;
}
.news-item .post-date>p {
    margin: 0;
    line-height: 1;
}
.news-item .post-date .month {
    background-color: #f36f21;
    color: #000;
    font-size: 18px;
    border-radius: 4px;
    padding: 4px 0;
}
.news-item .post-date .day {
    font-size: 56px;
    font-weight: 900;
    margin-bottom: 15px;
}
.news-item .post-date .year {
    font-size: 10px;
}
.news-item .news-box {
    margin-left: 118px;
    border: 1px solid #f36f21;
    border-radius: 4px;
    padding: 27px 22px;
    background-color: #000;
}
.news .news-title {
    font-size: 28px;
    margin-bottom: .6em;
}
.news .post-date, .news .text-section {
    font-size: 15px;
}
.news .back {
    margin-left: 118px;
    margin-top: 15px;
    display: inline-block;
}

.menu-btn {
    display: block;
    -webkit-transition: all .5s; /* Safari */
    transition: all .5s;
}
.menu-btn:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    color: #fff;
}

.shops .shop ul.info a:hover {
    color: #b8b8b8;
}

#privacy .container {
    padding: 45px 60px;
}

}