
/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
footer {
    background: url(../images/bg-foot.jpg) no-repeat;
    background-size: cover;
}

footer .social-networks {
    margin-bottom: 0;
}

.gotop {
    bottom: 90px;
    right: 25px;
}

section.home .lab {
    right: 40px;
    bottom: 15px;
}

#news {
    padding-bottom: 90px;
}

#about ul li a.mbtn+div {
    display: none!important;
}

#job-content>div {
    padding: 30px;
}

.tab-contents {
    overflow: hidden;
    position: relative;
}
.tab-contents>div {
    opacity: 0;
    transform:scale(.95);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    box-sizing: border-box;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.tab-contents>div.over {
    opacity: 1;
    z-index: 10;
    transform:scale(1);
}
.tab-contents>div>div {
    width: 100%;
    max-width: 990px;
    float: right;
    box-sizing: border-box;
}
.tab-contents>div:first-child {
    display: block;
}

.tab-contents .tab-cover {
    background-color: #272727;
}
.tab-contents .tab-cover img {
    margin-bottom: 60px;
}
.tab-contents .tab-cover p {
    max-width: 560px;
    margin: auto;
}

.tab-contents .tab-content>div {
    padding-left: 30px;
    padding-right: 30px;
}
.tab-contents .tab-content p {
    max-width: 480px;
    margin: 1em 0;
}

.tab-contents #tab1 {
    background: url(../images/tab1-bg.jpg) no-repeat;
    background-size: cover;
}
.tab-contents #tab1 p:last-child {
    margin-bottom: 70px;
}

.tab-contents #tab2 {
    background: url(../images/tab2-bg.jpg) no-repeat;
    background-size: cover;
}

.tab-contents #tab3 {
    background: url(../images/tab3-bg.jpg) no-repeat;
    background-size: cover;
}

.join {
    display: flex;
    align-items: center;
}


#about ul>li {
    margin-bottom: 0;
    overflow: hidden;
}
#about ul>li>a {
    display: block;
    max-width: 360px;
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
}
#about ul>li>a>img {
    display: block;
    /*border-bottom: 1px solid #6b6b6b;*/
    position: relative;
    z-index: 10;
}
#about ul>li>a>img[data-over] {
    display: none;
}
#about ul>li:last-child>a>img {
    border-bottom: none;
}
#about ul>li>a.selected>img {
    border-color: transparent;
}
#about ul>li>a:after {
    content: '';
    display: block;
    width: 2000em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: transparent;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

#about ul>li:nth-child(1)>a.selected:after, #about ul>li:nth-child(1)>a:hover:after {
    background-color: #000;
}
#about ul>li:nth-child(1)>a.selected>img {
    display: none;
}
#about ul>li:nth-child(1)>a.selected>img[data-over] {
    display: block;
}

#about ul>li:nth-child(2)>a.selected:after, #about ul>li:nth-child(2)>a:hover:after {
    background-color: #024288;
}
#about ul>li:nth-child(2)>a:hover>img {
    display: none;
}
#about ul>li:nth-child(2)>a:hover>img[data-over] {
    display: block;
}
#about ul>li:nth-child(3)>a.selected:after, #about ul>li:nth-child(3)>a:hover:after {
    background-color: #1eb9ee;
}

.banner h1 small {
    display: block;
    font-size: 14px;
    margin: 1em 0;
    letter-spacing: 0;
}

.banner .menu-nav {
    border-top: 1px solid #f36f21;
    background-color: rgba(0,0,0,.6);
    overflow: hidden;
    top: auto;
}
.banner .menu-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
}
.banner .menu-nav ul >li {
    float: left;
}
// .banner .menu-nav ul >li:first-child {
//     margin-right: 1em;
// }
.banner .menu-nav a {
    display: block;
    padding: .5em 1em;
    position: relative;
    font-size: 18px;
}
.banner .menu-nav .float-right a:hover, .banner .menu-nav .float-left li:first-child a:hover {
    color: #ad3703;
}
.banner .menu-nav a.icon {
    padding-left: 56px;
}
.banner .menu-nav a.icon img {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 17px;
    top: 50%;
    margin-top: -13px;
}
.banner .menu-nav a.selected {
    background-color: #f36f21;
    &:hover {
        color: #AD3703;
    }
}

.shops .name-box>h3 {
    padding-bottom: 0;
}
.shops .name-box>.icons {
    position: static;
}
.shops .name-box>.icons span {
    display: inline-block;
    margin-right: .5em;
}
.shops .name-box a.color-orange:hover {
    color: #fff!important;
}

#menu .icons-box>div>p>a:hover {
    color: #fff;
}

.contact .btn-primary { color: #fff; cursor: pointer; }
.contact .btn-primary:hover { background-color: #fff; border-color: #fff; color: #000; }

}